import React from "react";
import ReactApexChart from "react-apexcharts";
import './Ott.css';

const OttDonutChart = ({ ottDetails }) => {

    const activeUsers = ottDetails?.data?.active_users || 0;
    const inactiveUsers = ottDetails?.data?.inactive_users || 0;
    const totalUsers = ottDetails?.data?.users_count || 0;

    const chartOptions = {
        chart: {
            type: "donut",
        },
        labels: ["Active", "Inactive", "Total"],
        colors: ["#7459D9", "#9D85FF", "#AFA0E7"],
        plotOptions: {
            pie: {
                donut: {
                    size: "75%",
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: "Active",
                            formatter: () => activeUsers.toString(),
                        },
                    },
                },
            },
        },
        stroke: {
            width: 5,
            curve: 'smooth',
            lineCap: 'square',
        },
        legend: {
            show: true,
            markers: {
                width: 30,
                height: 4,
                shape: "circle",
                radius: 12,
                offsetX: -50,
            },
            formatter: (seriesName, opts) => {
                const values = [activeUsers, inactiveUsers, totalUsers];
                return `${seriesName}<br><span style="font-size: 14px; color: #1565C0; margin-left:40px;">${values[opts.seriesIndex]} users</span>`;
            },
        },
        dataLabels: {
            enabled: false,
        },
    };

    const chartSeries = [activeUsers, inactiveUsers, totalUsers];

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                padding: "10px",
            }}
        >
            <div
                style={{
                    width: "100%",
                    maxWidth: "600px",
                    height: "100%",
                    maxHeight: "400px",
                }}
                className="ott-donut-chart"
            >
                <ReactApexChart
                    options={chartOptions}
                    series={chartSeries}
                    type="donut"
                    height="100%"
                    width="100%"
                />
            </div>
        </div>
    );
};

export default OttDonutChart;
