import React, { Fragment } from "react";
import { Container, Row, Col, Label, FormGroup } from "reactstrap";

const PlanDetails = ({ plan }) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <TextField name="Operator Code" value={plan.operator_code} />
          <TextField name="Operator Name" value={plan.operator_name} />
          <TextField name="VBC Username" value={plan.vbc_username} />
          <TextField textarea name="Balance" value={plan.balance} />
        </Row>
      </Container>
    </Fragment>
  );
};

export default PlanDetails;

const NumericField = () => (
  <Col md="3" id="moveup">
    <FormGroup>
      <div className="input_wrap">
        <Label className="kyc_label">Plan CGST</Label>
        <input
          className={`form-control digits not-empty`}
          id="afterfocus"
          type="number"
          name="plan_cgst"
          min="0"
          onKeyDown={(evt) =>
            (evt.key === "e" ||
              evt.key === "E" ||
              evt.key === "." ||
              evt.key === "-") &&
            evt.preventDefault()
          }
          style={{ border: "none", outline: "none" }}
          disabled={true}
        ></input>
      </div>
    </FormGroup>
  </Col>
);

const TextField = ({ name, value, textarea }) => (
  <FormGroup>
    <div className="input_wrap ml-3">
      <Label className="kyc_label">{name}</Label>
      {textarea ? (
        <textarea
          id="afterfocus"
          type="text"
          name="package_name"
          value={value}
          disabled={true}
          style={{ height: 100 }}
        />
      ) : (
        <input
          className={`form-control digits not-empty`}
          id="afterfocus"
          type="text"
          name="package_name"
          style={{ border: "none", outline: "none" }}
          value={value}
          disabled={true}
        ></input>
      )}
    </div>
  </FormGroup>
);
