export const statusType = [
  {
    id: "ACT",
    name: "Active",
  },
  {
    id: "IN",
    name: "Inactive",
  },
];
