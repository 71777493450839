import React from "react";

const FooterContent = () => {
  return (
    <>
      <div class="footer1" style={{ position: "relative", bottom: "-95px" }}>
        <p> Powered by SPARK RADIUS</p>
      </div>
    </>
  );
};

export default FooterContent;
