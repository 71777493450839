import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Col, Label, Input, FormGroup, Button, Spinner } from 'reactstrap';
import { ottaxios } from '../../../../axios';

const OttOperatorDetails = ({ leadUser, onUpdate }) => {
    const [ottToggle, setOttToggle] = useState("off");
    const [isOttShow, setOttIsShow] = useState(false);
    const [ottFormData, setOttFormData] = useState({
        operator_name: '',
        operator_code: ''
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(true);


    useEffect(() => {
        setOttFormData((prev) => ({
            ...prev,
            franchise_name: leadUser.name
        }));
    }, [leadUser]);

    useEffect(() => {
        return () => {
            setIsMounted(false);
        };
    }, []);

    const OTTToggle = () => {
        setOttToggle(ottToggle === "off" ? "on" : "off");
        setOttIsShow(!isOttShow);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!ottFormData.operator_name) newErrors.operator_name = "Operator Name is required.";
        if (!ottFormData.operator_code) newErrors.operator_code = "Operator Code is required.";
        return newErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOttFormData((prev) => ({
            ...prev,
            franchise_name: leadUser.name,
            [name]: value
        }));
        setErrors((prev) => ({ ...prev, [name]: null }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        addOperator();
    };

    const addOperator = () => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        setIsLoading(true);
        ottaxios
            .post("/ott/add_operator", ottFormData, config)
            .then((res) => {
                if (!isMounted) return;
                if (res.data.status === "failure") {
                    toast.error(`Error: ${res.data.message}`, { autoClose: 2000 });
                } else {
                    toast.success("Operator added successfully!", { autoClose: 2000 });
                    onUpdate();
                    resetForm();
                }
                setIsLoading(false);
            })
            .catch((error) => {
                if (!isMounted) return;
                setIsLoading(false);
                let errorMessage = "Something went wrong";
                if (error.response && error.response.data) {
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else if (error.response.status === 500) {
                        errorMessage = "Internal server error";
                    } else if (error.response.status === 404) {
                        errorMessage = "API endpoint not found";
                    } else if (error.response.status === 401) {
                        errorMessage = "Unauthorized!";
                    }
                }
                toast.error(errorMessage, { autoClose: 2000 });
            });
    };

    const resetForm = () => {
        setOttFormData({ operator_name: '', operator_code: '', franchise_name: leadUser.name });
        setErrors({});
    };

    const [ottDetailsData, setottDetailsData] = useState([]);
    const getOttDetails = async () => {
        const operatorDetails = await ottaxios.get(`/ott/get_franchise_operator?franchise_name=${leadUser.name}`);
        if (operatorDetails?.data?.status === "success") {
            setottDetailsData(operatorDetails?.data)
        }
    }

    useEffect(() => {
        getOttDetails();
    }, [leadUser.name])

    return (
        <div>
            <Row>
                <Col sm="3">
                    <Label className="ott_label">OTT</Label>
                    <br />
                    <div
                        className={`franchise-switch ${ottDetailsData?.status === "success" ? "on" : ottToggle}`}
                        onClick={ottDetailsData?.status === "success" ? () => { } : OTTToggle}
                    />
                </Col>
            </Row>
            <br />
            {isOttShow ?
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col style={{ display: 'flex', columnGap: '20px' }}>
                            <FormGroup className="input_wrap">
                                <Label className="ott_operator_name_label">Operator Name*</Label>
                                <Input
                                    type="text"
                                    name="operator_name"
                                    value={ottDetailsData?.data?.operator_name ? ottDetailsData?.data?.operator_name : ottFormData.operator_name}
                                    onChange={handleChange}
                                    required
                                    invalid={!!errors.operator_name}
                                    disabled={ottDetailsData?.data?.operator_name}
                                />
                                {errors.operator_name && <div className="invalid-feedback">{errors.operator_name}</div>}
                            </FormGroup>
                            <FormGroup className="input_wrap">
                                <Label className="ott_operator_name_label">Operator Code*</Label>
                                <Input
                                    type="text"
                                    name="operator_code"
                                    value={ottDetailsData?.data?.operator_code ? ottDetailsData?.data?.operator_code : ottFormData.operator_code}
                                    onChange={handleChange}
                                    required
                                    invalid={!!errors.operator_code}
                                    disabled={ottDetailsData?.data?.operator_code}
                                />
                                {errors.operator_code && <div className="invalid-feedback">{errors.operator_code}</div>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button type="submit" color="primary" disabled={ottDetailsData?.status === "success" ? true : isLoading}>
                        {isLoading ? <Spinner size="sm" /> : "Add Operator"}
                    </Button>
                    <Button type="button" color="secondary" onClick={resetForm} style={{ marginLeft: '10px' }} disabled={ottDetailsData?.status === "success"}>
                        Reset
                    </Button>
                </form> :
                ottDetailsData?.status === "success" ?
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col style={{ display: 'flex', columnGap: '20px' }}>
                                <FormGroup className="input_wrap">
                                    <Label className="ott_operator_name_label">Operator Name*</Label>
                                    <Input
                                        type="text"
                                        name="operator_name"
                                        value={ottDetailsData?.data?.operator_name ? ottDetailsData?.data?.operator_name : ottFormData.operator_name}
                                        onChange={handleChange}
                                        required
                                        invalid={!!errors.operator_name}
                                        disabled={ottDetailsData?.data?.operator_name}
                                    />
                                    {errors.operator_name && <div className="invalid-feedback">{errors.operator_name}</div>}
                                </FormGroup>
                                <FormGroup className="input_wrap">
                                    <Label className="ott_operator_name_label">Operator Code*</Label>
                                    <Input
                                        type="text"
                                        name="operator_code"
                                        value={ottDetailsData?.data?.operator_code ? ottDetailsData?.data?.operator_code : ottFormData.operator_code}
                                        onChange={handleChange}
                                        required
                                        invalid={!!errors.operator_code}
                                        disabled={ottDetailsData?.data?.operator_code}
                                    />
                                    {errors.operator_code && <div className="invalid-feedback">{errors.operator_code}</div>}
                                </FormGroup>
                                <FormGroup className="input_wrap">
                                    <Label className="ott_operator_name_label">Balance</Label>
                                    <Input
                                        type="text"
                                        name="operator_code"
                                        value={ottDetailsData?.data?.balance}
                                        required
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button type="submit" color="primary" disabled={ottDetailsData?.status === "success" ? true : isLoading}>
                            {isLoading ? <Spinner size="sm" /> : "Add Operator"}
                        </Button>
                        <Button type="button" color="secondary" onClick={resetForm} style={{ marginLeft: '10px' }} disabled={ottDetailsData?.status === "success"}>
                            Reset
                        </Button>
                    </form> : ""
            }
        </div>
    );
};

export default OttOperatorDetails;
