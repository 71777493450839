import React from "react";
const CableTable = () => {
  return (
    <>
      <h3>CATV/IPTV</h3>
    </>
  );
};

export default CableTable;
