import React from "react";
const IPTVTable = () => {
  return (
    <>
      <h3>IPTV</h3>
    </>
  );
};

export default IPTVTable;
