import React, { Fragment, useState, useRef, useEffect, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import useFormValidation from "../../../customhooks/FormValidation";
import { ottaxios } from "../../../../axios";
import { toast } from "react-toastify";
import ErrorModal from "../../../common/ErrorModal";

const requiredFields = ["phone_number", "vbc_customer_id"];

const AddComboPlan = (props) => {
  const [loaderSpinner, setLoaderSpinner] = useState(false);
  const [formData, setFormData] = useState({
    phone_number: props.profileDetails?.register_mobile || "",
    vbc_customer_id: props.profileDetails?.user?.username || "",
  });
  const [errors, setErrors] = useState({});
  const [isDisabled, setDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [customers, setCustomers] = useState([]);
  const [showCustomers, setShowCustomers] = useState(false);

  const filteredCustomers = useMemo(() => {
    const text = formData.vbc_customer_id;
    return customers.filter(
      (customer) =>
        text.length && customer.toLowerCase().startsWith(text.toLowerCase()),
    );
  }, [customers, formData.vbc_customer_id]);

  useEffect(() => {
    setFormData({
      phone_number: props.profileDetails?.register_mobile || "",
      vbc_customer_id: props.profileDetails?.user?.username || "",
    });
  }, [props.profileDetails]);

  useEffect(() => {
    if (!customers.length) {
      setLoaderSpinner(true);
      ottaxios
        .get("/combo/get_customer_usernames")
        .then((res) => {
          if (res.data.status === "failure") {
            toast.error(`Error: ${res.data.message}`, { autoClose: 2000 });
          } else {
            setCustomers(res.data.data);
          }
          setLoaderSpinner(false);
        })
        .catch((error) => {
          setLoaderSpinner(false);
          toast.error("Something went wrong", { autoClose: 2000 });
        });
    }
  }, []);

  useEffect(() => {
    const hasEmptyValue = requiredFields.some(
      (field) => formData[field] === ""
    );
    setDisabled(hasEmptyValue || Object.keys(errors).length > 0);
  }, [errors, formData]);

  const handleInputChange = (e, isNumeric = false) => {
    if (e.target.name === "vbc_customer_id" && !showCustomers) {
      setShowCustomers(true);
    }
    setFormData({
      ...formData,
      [e.target.name]: isNumeric
        ? +Number(e.target.value).toFixed(2)
        : e.target.value,
    });
  };

  const resetForm = () => {
    setFormData({
      phone_number: props.profileDetails?.register_mobile || "",
      vbc_customer_id: props.profileDetails?.user?.username || "",
    });
    setErrors({});
  };

  const addSubscriber = () => {
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    setLoaderSpinner(true);
    ottaxios
      .post("/ott/user_add_subscriber", formData, config)
      .then((res) => {
        if (res.data.status === "failure") {
          toast.error(`Error: ${res.data.message}`, { autoClose: 2000 });
        } else {
          props.onUpdate(formData.vbc_customer_id);
          toast.success(res.data.message, { autoClose: 2000 });
        }
        setLoaderSpinner(false);
      })
      .catch((error) => {
        setLoaderSpinner(false);
        toast.error(error.response.data.message, { autoClose: 2000 });
      });
  };

  const validateForm = () => {
    let validationErrors = {};
    // Phone number validation
    if (!formData.phone_number) {
      validationErrors.phone_number = "Phone number is required.";
    } else if (formData.phone_number.toString().length < 10) {
      validationErrors.phone_number = "Phone number must be at least 10 digits.";
    } else if (formData.phone_number.toString().length > 10) {
      validationErrors.phone_number = "Phone number must be at most 10 digits.";
    }

    // Other field validation
    if (!formData.vbc_customer_id) {
      validationErrors.vbc_customer_id = "Customer ID is required.";
    }

    setErrors(validationErrors);
    return validationErrors;
  };

  const submit = (e) => {
    const validationErrors = validateForm();
    e.preventDefault();
    if (Object.keys(validationErrors).length === 0) {
      addSubscriber();
    }
  };

  useEffect(() => {
    validateForm()
  }, [formData.phone_number, formData.vbc_customer_id])
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form onSubmit={submit} id="myForm" onReset={resetForm} ref={useRef(null)}>
              <Row>
                <Col sm="3">
                  <FormGroup>
                    <Label className="kyc_label">Phone Number *</Label>
                    <Input
                      type="number"
                      name="phone_number"
                      onChange={(e) => {
                        if (e.target.value.toString().length > 10) return;
                        handleInputChange(e);
                      }}
                      className={`form-control digits ${formData.phone_number ? "not-empty" : ""}`}
                      value={formData.phone_number}
                    />
                    <span className="errortext">{errors.phone_number}</span>
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <Label className="kyc_label">Username *</Label>
                    <Input
                      type="text"
                      name="vbc_customer_id"
                      value={props.profileDetails?.user?.username || ""}
                      disabled
                      className="form-control digits"
                    />
                    <span className="errortext">{errors.vbc_customer_id}</span>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup className="mb-0">
                    <Button
                      color="btn btn-primary"
                      className="mr-3"
                      onClick={submit}
                      disabled={loaderSpinner || isDisabled}
                    >
                      {loaderSpinner ? <Spinner size="sm" /> : "Create"}
                    </Button>
                    <Button type="reset" color="btn btn-primary">Reset</Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <ErrorModal
          isOpen={showModal}
          toggle={() => setShowModal(false)}
          message={modalMessage}
          action={() => setShowModal(false)}
        />
      </Container>
    </Fragment>
  );
};

export default AddComboPlan;
