import React, { Fragment } from "react";
import { Container, Row, Col, Label, FormGroup } from "reactstrap";

const PlanDetails = ({ plan }) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <TextField name="Plan Code" value={plan.combo_plan_name} />
          <TextField
            name="Internet Plan Name"
            value={plan.internet_plan_name}
          />
          <TextField name="OTT Plan Name" value={plan.ott_plan_name} />
          <TextField name="Status" value={plan.plan_status} />
          <TextField name="Plan Cost" value={plan.basic} />
          <TextField name="Plan CGST %" value={plan.tax} />
          <TextField name="Total Cost" value={plan.total} />
        </Row>
      </Container>
    </Fragment>
  );
};

export default PlanDetails;

const NumericField = () => (
  <Col md="3" id="moveup">
    <FormGroup>
      <div className="input_wrap">
        <Label className="kyc_label">Plan CGST</Label>
        <input
          className={`form-control digits not-empty`}
          id="afterfocus"
          type="number"
          name="plan_cgst"
          min="0"
          onKeyDown={(evt) =>
            (evt.key === "e" ||
              evt.key === "E" ||
              evt.key === "." ||
              evt.key === "-") &&
            evt.preventDefault()
          }
          style={{ border: "none", outline: "none" }}
          // value={leadUser && leadUser.plan_cgst}
          disabled={true}
        ></input>
      </div>
    </FormGroup>
  </Col>
);

const TextField = ({ name, value }) => (
  <FormGroup>
    <div className="input_wrap ml-3">
      <Label className="kyc_label" style={{ fontSize: "16px" }}>
        {name}
      </Label>
      <input
        className={`form-control digits not-empty`}
        id="afterfocus"
        type="text"
        name="package_name"
        style={{ border: "none", outline: "none", fontSize: "16px" }}
        value={value}
        // onChange={handleChange}
        disabled={true}
      ></input>
    </div>
  </FormGroup>
);
