export const statusType = [
  {
    id: "active",
    name: "Active",
  },
  {
    id: "deactive",
    name: "Inactive",
  },
];

export const billingMode = [
  {
    id: "IBNK",
    name: "Internet Banking",
  },
  {
    id: "MBNK",
    name: "Mobile Banking",
  },
  {
    id: "EWLT",
    name: "Wallet",
  },
  {
    id: "DEBT",
    name: "Debit card",
  },
  {
    id: "CRDT",
    name: "Credit card",
  },
  {
    id: "UPI",
    name: "UPI",
  },
  {
    id: "CASH",
    name: "Cash",
  },
  {
    id: "CHEK",
    name: "Check",
  },
];

export const billingType = [
  {
    id: "PRE",
    name: "Prepaid",
  },
  {
    id: "POST",
    name: "Postpaid",
  },
];
