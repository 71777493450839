import React from "react";
import "./confirmation-modal.css";

const ConfirmationModal = ({ title, subtitle, onConfirm, onCancel }) => {
  return (
    <div className="confirmation-modal">
      <div className="confirmation-modal-header">
        <div className="modal-header-text">{title}</div>
        <div style={{ cursor: "pointer" }} onClick={onCancel}>
          &#x2715;
        </div>
      </div>
      <div className="confirmation-modal-content">{subtitle}</div>
      <div className="confirmation-modal-footer">
        <div className="modal-button cancel" onClick={onCancel}>
          Cancel
        </div>
        <div className="modal-button confirm" onClick={onConfirm}>
          Confirm
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
