const tableHeaders = [
  { name: "Customer ID", sortable: false },
  { name: "Customer Name", sortable: false },
  { name: "Phone Number", sortable: false },
  { name: "Franchise Name", sortable: false },
  { name: "Status", sortable: false },
  { name: "Plan Name", sortable: false },
  { name: "Plan Code", sortable: false },
  { name: "Plan Expiry", sortable: true },
  { name: "Queued Plan Code", sortable: false },
];

export default tableHeaders;
