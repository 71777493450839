import React from "react";

export const tableData = [
  {
    id: "1",
    name: "BHASHYAM-OLT",
    ipaddress: "172.29.132.80",
    branch: "BHASHYAM",
    posts: "7",
    location: "",
    descrption: "",
    lattitude: "0",
    longtitude: "0",
    status: <i className="fa fa-circle font-success f-12" />,
    creat_on: "2018-04-18T00:00:00",
  },
  {
    id: "2",
    name: "CMR-OLT",
    ipaddress: "172.29.132.6",
    branch: "CMR",
    posts: "4",
    location: "",
    descrption: "",
    lattitude: "0",
    longtitude: "0",
    status: <i className="fa fa-circle font-success f-12" />,
    creat_on: "2018-04-18T00:00:00",
  },
  {
    id: "3",
    name: "APOLLO-OLT",
    ipaddress: "172.29.132.2",
    branch: "APOLLO",
    posts: "9",
    location: "",
    descrption: "",
    lattitude: "0",
    longtitude: "0",
    status: <i className="fa fa-circle font-success f-12" />,
    creat_on: "2018-04-18T00:00:00",
  },
  {
    id: "4",
    name: "ASHOK NAGAR-OLT",
    ipaddress: "172.29.132.3",
    branch: "ASHOK NAGAR",
    posts: "3",
    location: "",
    descrption: "",
    lattitude: "0",
    longtitude: "0",
    status: <i className="fa fa-circle font-success f-12" />,
    creat_on: "2018-04-18T00:00:00",
  },
  {
    id: "5",
    name: "GANDHI NAGAR - OLT",
    ipaddress: "172.29.132.7",
    branch: "GANDHI NAGAR",
    posts: "5",
    location: "",
    descrption: "",
    lattitude: "0",
    longtitude: "0",
    status: <i className="fa fa-circle font-success f-12" />,
    creat_on: "2018-04-18T00:00:00",
  },
  {
    id: "6",
    name: "OFFICE-OLT",
    ipaddress: "172.29.134.8",
    branch: "OFFICE",
    posts: "8",
    location: "",
    descrption: "",
    lattitude: "0",
    longtitude: "0",
    status: <i className="fa fa-circle font-success f-12" />,
    creat_on: "2018-04-18T00:00:00",
  },
];
