import React, { useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import { iptvaxios } from "../../../../axios";
import { Box } from "@mui/material";
import ChannelList from "../iptv-plans/Headerbuttons/channellist";

const channelsHeaderList = [
  "",
  "Channel Id",
  "Name",
  "Description",
  "Genre",
  "Language",
  "Price",
  "CreatedBy",
];

const Channels = ({}) => {
  const [comboAdd, setComboAdd] = useState([]);
  const [channels, setChannels] = useState([]);
  // useEffect(() => {
  //   async function getChannels() {
  //     const response = await iptvaxios.get(
  //       "iptvadmin/admin/api/channel/getChannels/1"
  //     );
  //     setChannels(response.data.data);
  //   }
  //   getChannels();
  // }, []);

  useEffect(() => {
    var token = JSON.parse(localStorage.getItem("token"));
    var head = {
      Authorization:
        "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJjaGFuZHJhQG9ubmV0c3lzdGVtcy5uZXQiLCJyb2xlcyI6W3siaWQiOjIsInJvbGUiOiJhZG1pbiJ9XSwiaWF0IjoxNjQ1NDI5MjY1LCJleHAiOjE2NDYwMzQwNjV9.HAxuxZyfhnfncNUraot3jz-gylCBUZGrjprqDqIm7cg",
    };
    fetch(
      "https://keycloak.onnetsystems.ml/iptvadmin/admin/api/channel/getChannels/1",
      { headers: head },
    ).then((response) => setChannels(response.data.data));
  }, []);

  return (
    <>
      <ChannelList comboAdd={comboAdd} />
      {channels.length > 0 && (
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#f9f6fb" }}>
                <TableRow>
                  {channelsHeaderList.map((header, key) => (
                    <TableCell key={key}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {channels.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        onChange={(event) => {
                          if (event.target.checked) {
                            setComboAdd((prevState) => {
                              return [...prevState, row.id];
                            });
                          } else {
                            let filterSelected = comboAdd.filter(
                              (c) => c != row.id,
                            );
                            setComboAdd([...filterSelected]);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.channelId}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.genre}</TableCell>
                    <TableCell>{row.language}</TableCell>
                    <TableCell>{row.price}</TableCell>
                    <TableCell>{row.createdBy}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default Channels;
