import React, { Fragment } from "react";
import { Container, Row, Col, Label, FormGroup } from "reactstrap";
import OttIcon from "../../../../assets/images/ott-icon.png";



import ZEE5 from "../../../../assets/images/OTT ICONs/ZEE5@2x.png";
import ShemarooMe from "../../../../assets/images/OTT ICONs/Shemaroo@2x.png";
import NammaFlix from "../../../../assets/images/OTT ICONs/NammaFlix@2x.png";
import RajTv from "../../../../assets/images/OTT ICONs/Dummy.png";
import dollywoodPlay from "../../../../assets/images/OTT ICONs/Dollywood Play@2x.png";
import SonyLIV from "../../../../assets/images/OTT ICONs/Sony liv@2x.png";
import ShortsTV from "../../../../assets/images/OTT ICONs/shortTV@2x.png";
import PlayFlix from "../../../../assets/images/OTT ICONs/Playflix K-drama@2x.png";
import DocuBay from "../../../../assets/images/OTT ICONs/Docubay@2x.png";
import ptc from "../../../../assets/images/OTT ICONs/PTC Play@2x.png";
import FanCode from "../../../../assets/images/OTT ICONs/Fancode@2x.png";
import AAONXT from "../../../../assets/images/OTT ICONs/AAO NXT@2x.png";
import aha from "../../../../assets/images/OTT ICONs/Aha Telugu@2x.png";
import AhaTamil from "../../../../assets/images/OTT ICONs/Aha Tamil@2x.png";
import ALTT from "../../../../assets/images/OTT ICONs/ALTT@2x.png";
import ChaupalBhojpuri from "../../../../assets/images/OTT ICONs/Chaupal Bhojpuri@2x.png";
import chaupal from "../../../../assets/images/OTT ICONs/Chaupal@2x.png"
import CinemaWorld from "../../../../assets/images/OTT ICONs/Cinema World@2x.png";
import DangalPlay from "../../../../assets/images/OTT ICONs/Dangal Play@2x.png";
import DistroTV from "../../../../assets/images/OTT ICONs/Distro tv@2x.png";
import ETVWin from "../../../../assets/images/OTT ICONs/ETV@2x.png";
import istream from "../../../../assets/images/OTT ICONs/Istram@2x.png";
import KancchaLannka from "../../../../assets/images/OTT ICONs/Kanccha Lannka@2x.png";
import LIONSGATE from "../../../../assets/images/OTT ICONs/lionsgate play@2x.png";
import Manorama from "../../../../assets/images/OTT ICONs/Manorama Max@2x.png";
import OM from "../../../../assets/images/OTT ICONs/OM TV@2x.png";
import Powerkids from "../../../../assets/images/OTT ICONs/Powerkids Kartoon@2x.png";
import Bhaktiflix from "../../../../assets/images/OTT ICONs/sakthi Flix@2x.png";
import prag from "../../../../assets/images/OTT ICONs/Dummy.png";
import Premiumflix from '../../../../assets/images/OTT ICONs/Premiunflix@2x.png';
import Sanskar from "../../../../assets/images/OTT ICONs/Samskar@2x.png";
import Shortfundly from "../../../../assets/images/OTT ICONs/Shortfundly@2x.png";
import Stage from "../../../../assets/images/OTT ICONs/Stage@2x.png";
import SunNXT from "../../../../assets/images/OTT ICONs/SunNxt@2x.png";
import UltraJhakaas from "../../../../assets/images/OTT ICONs/ultra Jhakaas@2x.png";
import VROTT from "../../../../assets/images/OTT ICONs/Vr OTT@2x.png";
import ULLU from "../../../../assets/images/OTT ICONs/ULLU@2x.png";

const PlanDetails = ({ plan }) => {
  const ottIcons = {
    ZEE5: ZEE5,
    ShemarooMe: ShemarooMe,
    NammaFlix: NammaFlix,
    "Raj TV": RajTv,
    "Dollywood Play": dollywoodPlay,
    SonyLIV: SonyLIV,
    ShortsTV: ShortsTV,
    PlayFlix: PlayFlix,
    DocuBay: DocuBay,
    "PTC Play": ptc,
    FanCode: FanCode,
    AAONXT: AAONXT,
    aha: aha,
    'aha Tamil': AhaTamil,
    ALTT: ALTT,
    "Chaupal Bhojpuri": ChaupalBhojpuri,
    CHAUPAL: chaupal,
    CinemaWorld: CinemaWorld,
    "Dangal Play": DangalPlay,
    DistroTV: DistroTV,
    "ETV Win": ETVWin,
    istream: istream,
    "Kanccha Lannka": KancchaLannka,
    "LIONSGATE PLAY": LIONSGATE,
    "Manorama Max": Manorama,
    "OM TV": OM,
    "Powerkids Plus Kartoon": Powerkids,
    Bhaktiflix: Bhaktiflix,
    "Prag Play": prag,
    Premiumflix: Premiumflix,
    Sanskar: Sanskar,
    Shortfundly: Shortfundly,
    Stage: Stage,
    SunNXT: SunNXT,
    ULLU: ULLU,
    "Ultra Jhakaas": UltraJhakaas,
    VROTT: VROTT,
  };
  return (
    <Fragment>
      <Container fluid={true}>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <TextField name="Plan Code" value={plan.plan_code} />
          <TextField
            name="Duration"
            value={
              plan.plan_duration +
              " " +
              "Month" +
              (plan.plan_duration > 1 ? "s" : "")
            }
          />
          <TextField name="Status" value={plan.status} />
          <TextField name="Plan Cost" value={plan.basic} />
          <TextField name="Plan Tax" value={plan.tax} />
          <TextField name="Total Cost" value={plan.total} />
          {/* <TextField
            textarea
            name="OTT Services"
            value={plan.ott_services.toString().split(",").join(" , ")}
          /> */}
        </Row>
        <Row>
          <div style={{ marginTop: 30, fontSize: "16px" }}>
            <div>Ott Services</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 10,
              }}
            >
              {(plan.ott_services || []).map((ott, idx) => (
                <div
                  key={idx}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 10,
                    marginRight: 20,
                  }}
                >
                  <img
                    style={{
                      objectFit: "contain",
                      height: "35px",
                      width: "35px",
                      marginBottom: '5px',
                    }}
                    src={ottIcons[ott]}
                    alt="ott-icon"
                  />
                  {ott}
                </div>
              ))}
            </div>
          </div>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PlanDetails;

const NumericField = () => (
  <Col md="3" id="moveup">
    <FormGroup>
      <div className="input_wrap">
        <Label className="kyc_label">Plan CGST</Label>
        <input
          className={`form-control digits not-empty`}
          id="afterfocus"
          type="number"
          name="plan_cgst"
          min="0"
          onKeyDown={(evt) =>
            (evt.key === "e" ||
              evt.key === "E" ||
              evt.key === "." ||
              evt.key === "-") &&
            evt.preventDefault()
          }
          style={{ border: "none", outline: "none" }}
          // value={leadUser && leadUser.plan_cgst}
          disabled={true}
        ></input>
      </div>
    </FormGroup>
  </Col>
);

const TextField = ({ name, value, textarea }) => (
  <FormGroup>
    <div className="input_wrap ml-3">
      <Label className="kyc_label" style={{ fontSize: "16px" }}>
        {name}
      </Label>
      {textarea ? (
        <textarea
          id="afterfocus"
          type="text"
          name="package_name"
          value={value}
          disabled={true}
          style={{ height: 100, fontSize: "16px" }}
        />
      ) : (
        <input
          className={`form-control digits not-empty`}
          id="afterfocus"
          type="text"
          name="package_name"
          style={{ border: "none", outline: "none", fontSize: "16px" }}
          value={value}
          disabled={true}
        ></input>
      )}
    </div>
  </FormGroup>
);
