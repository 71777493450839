export const getNetworkReportTableColumn = () => {
  const customerRepotsTableColumns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
  ];
  return customerRepotsTableColumns;
};
