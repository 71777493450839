import React, { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/Inbox";
import GroupIcon from "@mui/icons-material/Group";
import LanguageIcon from "@mui/icons-material/Language";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import ottplay from "../../../../assets/images/OTT ICONs/Ott Subscriber Icon.png";
import operatorIcon from "../../../../assets/images/OTT ICONs/OperatorList.png";
import comboIcon from "../../../../assets/images/OTT ICONs/ComboPlanUser.png";
import ottplanIcon from "../../../../assets/images/OTT ICONs/OTTPlanList.png";

import { ottaxios } from "../../../../axios";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import KPI from "./KPI";
const AllReports = () => {
  const [exportData, setExportData] = useState([]);
  const [operatorData, setOperatorData] = useState([]);
  const [ottplan, setOttPlan] = useState([]);
  const [comboplan, setComboPlan] = useState([]);

  const getData = async () => {
    try {
      const data = await ottaxios.get("/ott/subscriber_details/?export=true");
      if (data?.data?.status === "success") {
        setExportData(data?.data?.data?.result);
      }
    } catch (error) {
      toast.error(error.response.data?.message);
    }
  };

  const getOperatorData = async () => {
    try {
      const data = await ottaxios.get("/ott/get_operator_details?export=true");
      if (data?.data?.status === "success") {
        setOperatorData(data?.data?.data?.result);
      }
    } catch (error) {
      toast.error(error.response.data?.message);
    }
  };

  const getOttPlanData = async () => {
    try {
      const data = await ottaxios.get("ott/plans_list?export=true");
      if (data?.data?.status === "success") {
        setOttPlan(data?.data?.data?.result);
      }
    } catch (error) {
      toast.error(error.response.data?.message);
    }
  };

  const getComboPlanData = async () => {
    try {
      const data = await ottaxios.get("/combo/get_plans?export=true");
      if (data?.data?.status === "success") {
        setComboPlan(data?.data?.data?.results);
      }
    } catch (error) {
      toast.error(error.response.data?.message);
    }
  };

  useEffect(() => {
    getData();
    getOperatorData();
    getOttPlanData();
    getComboPlanData();
  }, []);

  const downloadExcelFileForServices = (data, type, name) => {
    const wb = XLSX.utils.book_new();
    const ws_name = name;

    const headers = Object.keys(data[0] || {}).map(key => key.replace(/_/g, ' '));

    const ws_data = data.map(item => {
      let newItem = {};
      headers.forEach(header => {
        const key = header.replace(/ /g, '_');
        const value = item[key];
        newItem[header] = Array.isArray(value) ? value.join(", ") : value;
      });
      return newItem;
    });

    const ws = XLSX.utils.json_to_sheet(ws_data);

    // Append headers to the worksheet
    headers.forEach((header, index) => {
      ws[XLSX.utils.encode_cell({ r: 0, c: index })] = { v: header, t: 's' };
    });

    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, `${name} Reports${type === "csv" ? ".csv" : ".xlsx"}`);

    toast.success("File downloaded successfully.", {
      autoClose: 1000,
    });
  };

  return (
    <Fragment>
      <br />
      <br />
      <Box sx={{ flexGrow: 1, backgroundColor: "white", padding: "20px" }}>
        <h3>Reports</h3>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <nav aria-label="main mailbox folders">
              <List>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <GroupIcon />
                    </ListItemIcon>
                    <Link
                      to={`${process.env.PUBLIC_URL}/app/Reports/customerreports/customerreports/${process.env.REACT_APP_API_URL_Layout_Name}`}
                    >
                      <ListItemText primary="Customer Reports" />
                    </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <Link
                      to={`${process.env.PUBLIC_URL}/app/Reports/customerreports/revenueReports/${process.env.REACT_APP_API_URL_Layout_Name}`}
                    >
                      <ListItemText primary="Revenue Reports" />
                    </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <LanguageIcon />
                    </ListItemIcon>
                    <Link
                      to={`${process.env.PUBLIC_URL}/app/Reports/customerreports/franchiseReports/${process.env.REACT_APP_API_URL_Layout_Name}`}
                    >
                      <ListItemText primary="Franchise Reports" />
                    </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <Link
                      to={`${process.env.PUBLIC_URL}/app/Reports/billingreports/securityfields/${process.env.REACT_APP_API_URL_Layout_Name}`}
                    >
                      <ListItemText primary="Security Deposit Reports" />
                    </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => { downloadExcelFileForServices(exportData, 'csv', 'OTT Subscribers'); }}>
                    <ListItemIcon>
                      <img
                        style={{
                          objectFit: "contain",
                          height: "24px",
                          width: "24px",
                        }}
                        src={ottplay}
                        alt="ott-icon"
                      />
                    </ListItemIcon>
                    <Link><ListItemText primary="OTT Subscribers Reports" /></Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => { downloadExcelFileForServices(operatorData, 'csv', 'Operators Detail'); }}>
                    <ListItemIcon>
                      <img
                        style={{
                          objectFit: "contain",
                          height: "24px",
                          width: "24px",
                        }}
                        src={operatorIcon}
                        alt="ott-icon"
                      />
                    </ListItemIcon>
                    <Link><ListItemText primary="Operators Detail Reports" /></Link>
                  </ListItemButton>
                </ListItem>
              </List>
            </nav>
          </Grid>
          <Grid item xs={4}>
            <nav aria-label="main mailbox folders">
              <List>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <Link
                      to={`${process.env.PUBLIC_URL}/app/Reports/customerreports/helpdeskReports/${process.env.REACT_APP_API_URL_Layout_Name}`}
                    >
                      <ListItemText primary="Helpdesk Reports" />
                    </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <Link
                      to={`${process.env.PUBLIC_URL}/app/Reports/billingreports/invoicefields/${process.env.REACT_APP_API_URL_Layout_Name}`}
                    >
                      <ListItemText primary="Invoice Reports" />
                    </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Wallet Reports" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <RssFeedIcon />
                    </ListItemIcon>
                    <Link
                      to={`${process.env.PUBLIC_URL}/app/Reports/networkreports/networkfields/${process.env.REACT_APP_API_URL_Layout_Name}`}
                    >
                      <ListItemText primary="Network Reports" />
                    </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => { downloadExcelFileForServices(ottplan, 'csv', 'OTT Plan'); }}>
                    <ListItemIcon>
                      <img
                        style={{
                          objectFit: "contain",
                          height: "24px",
                          width: "24px",
                        }}
                        src={ottplanIcon}
                        alt="ott-icon"
                      />
                    </ListItemIcon>
                    <Link><ListItemText primary="OTT Plan Reports" /></Link>
                  </ListItemButton>
                </ListItem>
              </List>
            </nav>
          </Grid>
          <Grid item xs={4}>
            <nav aria-label="main mailbox folders">
              <List>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <Link
                      to={`${process.env.PUBLIC_URL}/app/Reports/billingreports/ledgerfields/${process.env.REACT_APP_API_URL_Layout_Name}`}
                    >
                      <ListItemText primary="Ledger Reports" />
                    </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <Link
                      to={`${process.env.PUBLIC_URL}/app/Reports/billingreports/gstfields/${process.env.REACT_APP_API_URL_Layout_Name}`}
                    >
                      <ListItemText primary="GST Reports" />
                    </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Discount Reports" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <Link
                      to={`${process.env.PUBLIC_URL}/app/Reports/customerreports/leadsReports/${process.env.REACT_APP_API_URL_Layout_Name}`}
                    >
                      <ListItemText primary="Leads Reports" />
                    </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => { downloadExcelFileForServices(comboplan, 'csv', 'Combo Plan'); }}>
                    <ListItemIcon>
                      <img
                        style={{
                          objectFit: "contain",
                          height: "24px",
                          width: "24px",
                        }}
                        src={comboIcon}
                        alt="ott-icon"
                      />
                    </ListItemIcon>
                    <Link><ListItemText primary="Combo Plan Reports" /></Link>
                  </ListItemButton>
                </ListItem>
              </List>
            </nav>
          </Grid>
        </Grid>
        <br />
        <br />
        <KPI />
      </Box>
    </Fragment>
  );
};

export default AllReports;
