const tableHeaders = [
  { name: "Plan Name", sortable: true },
  { name: "Plan Code", sortable: false },
  { name: "Internet Plan Name", sortable: false },
  { name: "OTT Plan Name", sortable: false },
  { name: "Status", sortable: false },
  { name: "VBC Username", sortable: false },
  { name: "Franchise Name", sortable: false },
  { name: "Total Cost", sortable: true },
];

export default tableHeaders;
