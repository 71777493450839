import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    Dropdown,
    Spinner,
} from "reactstrap";
import { downloadePdfForServices, downloadExcelFileForServices } from "../../../application/administration/branchdetails/Export";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MUIButton from "@mui/material/Button";
import EXPORT from "../../../../assets/images/export.png";
import Tooltip from "@mui/material/Tooltip";
import { removeUnderscore } from "./downloadUtils";

const ExportDownload = ({ tableData, name, titleOfFile }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [download, setDownload] = useState({});
    const [data, setData] = useState([]);

    //states for data filter based on start date and end date
    const [basedondate, setBasedondate] = useState([]);
    //export states
    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [filteredData, setFiltereddata] = useState(data);
    const [isExportDataModalOpen, setIsExportDataModalToggle] = useState(false);
    const [downloadableData, setDownloadableExcelData] = useState([]);
    const [filteredDataForModal, setFilteredDataForModal] =
        useState(filteredData);
    const [downloadAs, setDownloadAs] = useState("");
    const [headersForExport, setHeadersForExport] = useState([]);
    //  Sailaja added for Export Download Button Loader on 15th May 2023
    const [isLoading, setIsLoading] = useState(false);

    //export functionality start here
    const handleExportDataModalOpen = (downloadAs) => {
        setAnchorEl(null)
        setIsExportDataModalToggle(!isExportDataModalOpen);
        setDownloadableExcelData(tableData);
        setDownloadAs(downloadAs);
    };

    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            if (event.target.defaultValue === "all") {
                let allKeys = headersForExportFile?.map((h) => h[0]);
                setHeadersForExport(allKeys);
            } else {
                let list = [...headersForExport];
                list.push(event.target.defaultValue);
                setHeadersForExport(list);
            }
        } else {
            if (event.target.defaultValue === "all") {
                setHeadersForExport([]);
            } else {
                let removedColumnFromHeader = headersForExport.filter(
                    (item) => item !== event.target.defaultValue,
                );
                setHeadersForExport(removedColumnFromHeader);
            }
        }
    };

    const headersForExportFile = [
        ["all", "All"],
        ...Object.keys(tableData[0])?.map(key => [key, removeUnderscore(key)]) || []
    ];

    const handleDownload = () => {
        setIsLoading(true);
        const headers = headersForExportFile?.filter((header) => header !== "all");
        const headersForPDF = headersForExportFile?.filter(
            (h) => headers.includes(h[0]) && h,
        );
        setIsLoading(false);
        if (downloadAs === "pdf") {
            handleExportDataAsPDF(headers);
        } else {
            downloadExcelFileForServices(tableData, downloadAs, headers);
        }
        toggleDropdown();
        setHeadersForExport([]);
        setFilteredDataForModal(filteredData);
        setIsExportDataModalToggle(false);
    };

    const handleExportDataAsPDF = (headersForPDF) => {
        setIsExportDataModalToggle(!isExportDataModalOpen);
        const title = titleOfFile;
        downloadePdfForServices(title, headersForPDF, tableData, name);
    };


    const handleExportclose = () => {
        setIsExportDataModalToggle(false);
        setHeadersForExport([]);
    };
    //end

    return (
        <Fragment>
            <br />
            <div>
                <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    className="export-dropdown"
                >
                    {/* Sailaja Commented off the code of Branch Ledger Export  on 15th May 2023 */}
                </Dropdown>

                <Modal
                    isOpen={isExportDataModalOpen}
                    toggle={() => {
                        setIsExportDataModalToggle(!isExportDataModalOpen);
                        setFilteredDataForModal(filteredData);
                        setHeadersForExport([]);
                    }}
                    centered
                >
                    <ModalBody>
                        <h5> Select the Fields Required </h5>
                        <hr />
                        <div>
                            {headersForExportFile?.map((column, index) => (
                                <span style={{ display: "block" }}>
                                    <label for={column[1]} key={`${column[1]}${index}`}>
                                        <input
                                            value={column[0]}
                                            onChange={handleCheckboxChange}
                                            type="checkbox"
                                            name={column[1]}
                                            checked={headersForExport.includes(column[0])}
                                        />
                                        &nbsp; {column[1]}
                                    </label>
                                </span>
                            ))}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" id="resetid" onClick={handleExportclose}>
                            Close
                        </Button>
                        {/* Sailaja Added new Download Button on 15th May 2023 */}
                        <Button
                            color="primary"
                            className="btn btn-primary openmodal"
                            id="download_button1"
                            onClick={() => handleDownload()}
                            disabled={
                                isLoading || (headersForExport.length > 0 ? false : true)
                            }
                        // disabled={headersForExport.length > 0 ? false : true}
                        >
                            {isLoading ? <Spinner size="sm" /> : null}
                            &nbsp;&nbsp; Download
                        </Button>
                    </ModalFooter>
                </Modal>

                <Form>
                    {/* Sailaja modified marginTop Property on 15th May 2023 */}
                    <Row>
                        {/* Sailaja Added New Export In Branch Edit Ledger in 15th May 2023 */}
                        <Col sm="4">
                            <>
                                <Tooltip title={"Export"}>
                                    <MUIButton
                                        className="muibuttons"
                                        variant="outlined"
                                        onClick={handleClick}
                                        style={{ height: "40px" }}
                                    >
                                        <img src={EXPORT} className="Header_img" />
                                    </MUIButton>
                                </Tooltip>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                >
                                    <MenuItem onClick={() => handleExportDataModalOpen("csv")}>
                                        Export CSV
                                    </MenuItem>
                                    <MenuItem onClick={() => handleExportDataModalOpen("excel")}>
                                        Export XLS
                                    </MenuItem>
                                </Menu>
                            </>
                        </Col>
                    </Row>

                </Form>
            </div>
        </Fragment>
    );
};

export default ExportDownload;
