import React from "react";

const tableCellStyle = {
  textAlign: "center",
  paddingRight: 0,
  paddingLeft: 0,
};

const Row = ({ row, onSelectRow, serviceIdClickHandler }) => {
  return (
    <tr key={row.id}>
      <td
        style={{
          textTransform: "uppercase",
          textAlign: "center",
        }}
      >
        <span onClick={() => serviceIdClickHandler(row)}>
          {row.vbc_customer_id}
        </span>
      </td>
      <td
        style={{
          color: "black",
          textAlign: "center",
        }}
      >{`${row.first_name} ${row.last_name}`}</td>
      <td style={tableCellStyle}>{row.phone_number}</td>
      <td style={tableCellStyle}>{row.franchise_name || "-"}</td>
      <td style={tableCellStyle}>{row.status}</td>
      <td style={tableCellStyle}>{row.plan_name || "-"}</td>
      <td style={tableCellStyle}>{row.plan_code || "-"}</td>
      <td style={tableCellStyle}>
        {row.plan_expiry_date
          ? new Date(row.plan_expiry_date).toDateString()
          : "-"}
      </td>
      <td style={tableCellStyle}>{row.queued_plan_code || "-"}</td>
    </tr>
  );
};

export default Row;
