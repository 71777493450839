import React from "react";
import OttIcon from "../../../../assets/images/ott-icon.png";

const tableCellStyle = {
  textAlign: "center",
  paddingRight: 0,
  paddingLeft: 0,
};

const Row = ({ row, onSelectRow, serviceIdClickHandler }) => {
  return (
    <tr key={row.id}>
      <td
        style={{
          textTransform: "uppercase",
          textAlign: "left",
          paddingLeft: 40,
          paddingRight: 0,
          // width: "auto",
          maxWidth: "100px",
        }}
      >
        <input
          type="checkbox"
          checked={!!row.isSelected}
          onChange={() => onSelectRow(row, !row.isSelected)}
        />
        <span className="divider" />
        <span onClick={() => serviceIdClickHandler(row)}>{row.plan_name}</span>
      </td>
      <td style={{ ...tableCellStyle, color: "black" }}>{row.plan_code}</td>
      <td style={tableCellStyle}>
        {row.plan_duration + " " + "Month" + (row.plan_duration > 1 ? "s" : "")}
      </td>
      <td style={tableCellStyle}>{row.status}</td>
      {/* <td style={tableCellStyle}> ₹ {parseFloat(row.basic).toFixed(2)}</td> */}
      {/* <td style={tableCellStyle}> ₹ {((row.basic * 9) / 100).toFixed(2)}</td> */}
      {/* <td style={tableCellStyle}> ₹ {((row.basic * 9) / 100).toFixed(2)}</td> */}
      <td style={tableCellStyle}>{row.franchise_name || "-"}</td>
      <td style={tableCellStyle}> ₹ {parseFloat(row.total).toFixed(2)}</td>
      <td style={tableCellStyle}>{row.ott_services.length}</td>
      {/* <td style={{ position: "relative", ...tableCellStyle }}>
        <img
          style={{ objectFit: "contain", height: "35px", width: "35px" }}
          src={OttIcon}
          alt="ott-icon"
        />{" "}
        <img
          style={{
            objectFit: "contain",
            height: "35px",
            width: "35px",
            position: "absolute",
            left: "5px",
          }}
          src={OttIcon}
          alt="ott-icon"
        />{" "}
        +{row.ott_services.length - 2}
      </td> */}
    </tr>
  );
};

export default Row;
