import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Card, CardHeader, CardBody, Spinner } from "reactstrap";
import OttDonutChart from "./OttDonutChart";
const OTTCard = React.memo((props) => {
    return (
        <>
            <Grid item xs={12} sm={12} lg={12} xl={12} md={12}>
                <Card
                    style={{ borderRadius: "10px", flex: "0 0 100%", height: "100%" }}
                >
                    {" "}
                    {props.loaderSpinneer ? (
                        <Grid container spacing={2} className="loadercenter">
                            <Spinner size="lg" className="dashboard_spinner">
                                {" "}
                            </Spinner>
                        </Grid>
                    ) : (
                        <>
                            <CardHeader style={{ padding: "5px", borderBottom: "0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                                        <div style={{ display: "flex" }}>
                                            <div
                                                className="dashboard-font"
                                                style={{
                                                    position: "relative",
                                                    left: "15px",
                                                    // marginBottom: "10px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                OTT Customers
                                            </div>{" "}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardHeader>

                            <CardBody style={{ padding: "0px", margin: "0px" }}>
                                <OttDonutChart
                                    ottDetails={props?.ottDetails}
                                />
                            </CardBody>
                        </>
                    )}
                </Card>
            </Grid>
        </>
    );
});

export default OTTCard;
