import React, { useState, useEffect } from "react";
import { Form, FormGroup, Row } from "reactstrap";

const OTPModal = ({ showModal, setShowModal, onResend, onSubmit }) => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [disabled, setDisabled] = useState(false);
  const [timer, setTimer] = useState(60);

  const handleResendPress = () => {
    onResend();
    setDisabled(true);
    setTimer(60);
  };

  useEffect(() => {
    if (disabled) {
      const interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [disabled]);

  const handleChange = (index, value) => {
    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleSubmit = () => {
    if (otp.join("").length === 6) {
      onSubmit(otp.join(""));
      setOtp(Array(6).fill(""));
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setOtp(Array(6).fill(""));
    setDisabled(false);
    setTimer(60);
  };

  return showModal ? (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 100,
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          padding: "20px",
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
          width: "400px",
          textAlign: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            fontSize: "20px",
            cursor: "pointer",
            position: "absolute",
            top: "15px",
            right: "20px",
          }}
          onClick={handleClose}
        >
          &#x2715;
        </div>
        <h2 style={{ margin: "0 0 20px 0", fontWeight: "bold" }}>Enter OTP</h2>
        <Form id="myForm" style={{ display: "flex", justifyContent: "center" }}>
          <Row style={{ justifyContent: "center" }}>
            {otp.map((value, index) => (
              <FormGroup key={index} style={{ margin: "0 5px" }}>
                <input
                  type="text"
                  id={`otp-input-${index}`}
                  value={value}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  maxLength={1}
                  style={{
                    width: "40px",
                    height: "40px",
                    fontSize: "20px",
                    textAlign: "center",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    outline: "none",
                    transition: "border-color 0.3s",
                  }}
                  onFocus={(e) => e.target.select()}
                />
              </FormGroup>
            ))}
          </Row>
        </Form>
        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              backgroundColor: disabled ? "#ccc" : "#007bff",
              color: "#fff",
              padding: "10px 15px",
              borderRadius: "4px",
              cursor: disabled ? "not-allowed" : "pointer",
              opacity: disabled ? 0.7 : 1,
              marginBottom: "10px",
            }}
            onClick={!disabled ? handleResendPress : null}
          >
            {disabled ? `Resend in ${timer}s` : "Resend OTP"}
          </div>
          <div
            onClick={otp.join("").length === 6 ? handleSubmit : null}
            style={{
              backgroundColor: otp.join("").length === 6 ? "#3D639F" : "#ccc",
              color: "#fff",
              padding: "10px 15px",
              borderRadius: "4px",
              cursor: otp.join("").length === 6 ? "pointer" : "not-allowed",
              opacity: otp.join("").length === 6 ? 1 : 0.6,
            }}
          >
            Submit
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default OTPModal;
