import React, { useCallback, useState } from "react";
import { Row, Col } from "reactstrap";

import PlanDetails from "./plan-details";

const PlanDetailsContainer = React.forwardRef((props, ref) => {
  const [activeTab, setActiveTab] = useState(null);

  const {
    closeCustomizer1,
    isServiceDetailsOpen,
    isSessionHistoryOpen,
    selectedRow,
    RefreshHandler,
    detailsUpdate,
  } = props;

  const isOpen = isServiceDetailsOpen || isSessionHistoryOpen ? " open" : "";

  const handleClose = useCallback(() => {
    setActiveTab(null);
    closeCustomizer1();
  }, [closeCustomizer1]);

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <div className={`customizer-contain${isOpen}`} ref={ref}>
            <div className="tab-content" id="c-pills-tabContent">
              <div className="customizer-header">
                <br />
                <i className="icon-close" onClick={handleClose}></i>
                <br />
              </div>
              <div className="customizer-body custom-scrollbar">
                {selectedRow && isServiceDetailsOpen && (
                  <>
                    <div id="headerheading">
                      Customer Information : {selectedRow.vbc_customer_id}
                    </div>
                    <PlanDetails plan={selectedRow} />
                  </>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
});

export default PlanDetailsContainer;
