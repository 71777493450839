import React, { useState, useEffect, useMemo } from "react";
import MUIButton from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import REFRESH from "../../../../assets/images/refresh.png";
import AddIcon from "@mui/icons-material/Add";
// import AddOffers from "./AddOffers";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import debounce from "lodash.debounce";
import Tooltip from "@mui/material/Tooltip";

import { OTT_SUBSCRIBERS, SERVICEPLAN } from "../../../../utils/permissions";
import { ottaxios } from "../../../../axios";
import { toast } from "react-toastify";
import ExportDownload from "../ExportDownload/ExportDownload";

var storageToken = localStorage.getItem("token");
var tokenAccess = "";
if (storageToken !== null) {
  var token = JSON.parse(storageToken);
  var tokenAccess = token?.access;
}
export const ServiceHeaderButtons = (props) => {
  const [exportData, setExportData] = useState([]);
  const changeHandler = (event) => {
    props.setSearchUser(event.target.value);
  };

  const debouncedChangeHandler = useMemo(() => {
    return debounce(changeHandler, 500);
  }, []);
  const getExportData = async () => {
    try {
      const data = await ottaxios.get("/ott/subscriber_details/?export=true");
      if (data?.data?.status === "success") {
        setExportData(data?.data?.data?.result);
      }
    } catch (error) {
      toast.error(error.response.data?.message);
    }
  }

  useEffect(() => {
    getExportData();
  }, [])

  return (
    <React.Fragment>
      <div>
        <Stack direction="row" spacing={2}>
          <span className="all_cust">OTT Subscribers</span>
          {/* <AddOffers /> */}
          <Stack direction="row" justifyContent="flex-end" sx={{ flex: 1 }}>
            <Paper
              component="div"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
                height: "40px",
                boxShadow: "none",
                border: "1px solid #E0E0E0",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search With Customer Id"
                inputProps={{ "aria-label": "search google maps" }}
                onChange={debouncedChangeHandler}
              />
              <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            {exportData?.length > 0 && <ExportDownload tableData={exportData} name={"OTT Subscribers Information"} titleOfFile={"OTT Subscribers"} />}
            <Tooltip title={"Refresh"}>
              <MUIButton
                onClick={props.RefreshHandler}
                variant="outlined"
                className="muibuttons"
              >
                <img src={REFRESH} style={{ width: "20px" }} />
              </MUIButton>
            </Tooltip>
            {/* {token.permissions.includes(OTT_SUBSCRIBERS.ADD_SUBSCRIBER) && (
              <button
                className="btn btn-primary openmodal"
                id="newbuuon"
                type="submit"
                onClick={() => props.openCustomizer("2")}
              >
                <b>
                  <span
                    className="openmodal"
                    style={{ fontSize: "16px", marginLeft: "-9px" }}
                  >
                    New &nbsp;&nbsp;
                  </span>
                </b>
                <i
                  className="icofont icofont-plus openmodal"
                  style={{
                    cursor: "pointer",
                  }}
                ></i>
              </button>
            )} */}
          </Stack>
        </Stack>
      </div>
    </React.Fragment>
  );
};
