import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ottaxios } from "../../../axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PaymentPage = () => {
  const { state } = useLocation();
  const [isPaymentPending, setIsPaymentPending] = useState(true);

  const {
    merchTxnId,
    atomTokenId,
    amount,
    custMobile,
    merchId,
    custEmail,
    returnUrl,
    atomUrl,
  } = state;

  const pollForStatus = async () => {
    ottaxios
      .post(`/epay/payment_status`, { merchant_txn_id: merchTxnId })
      .then((response) => {
        const {
          data: { status, message },
        } = response;
        console.log(message, 'message')
        if (status !== "success") {
          toast.error(message, { autoClose: 2000 });
          setIsPaymentPending(false);
          setTimeout(() => {
            window.location.href = "app/customermanagement/customerlists/vbc";
          }, 3000)
        }
        if (message === "Payment pending") return;
        toast.success(message, { autoClose: 2000 });
        setIsPaymentPending(false);
      })
      .catch((error) => {
        setIsPaymentPending(false);
        console.log(error.response.data.message, 'error.response.data.message')
        if (error.response.data.message) {
          toast.error(error.response.data.message, { autoClose: 2000 });
          setTimeout(() => {
            window.location.href = "app/customermanagement/customerlists/vbc";
          }, 3000)
        }
        let errorMessage = "Something went wrong";
        if (error.response && error.response.data) {
          if (error.response.data["package_name"].length > 0) {
            errorMessage = error.response.data["package_name"][0];
          } else if (error.response.status === 500) {
            errorMessage = "Internal server error";
          } else if (error.response.status === 404) {
            errorMessage = "API endpoint not found";
          } else if (error.response.status === 401) {
            errorMessage = "Unauthorized!";
          }
        }
        toast.error(errorMessage, { autoClose: 2000 });
        setTimeout(() => {
          window.location.href = "app/customermanagement/customerlists/vbc";
        }, 3000)
      });
  };

  useEffect(() => {
    const script = document.createElement("script");

    script.src = atomUrl;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [atomUrl]);

  useEffect(() => {
    if (!isPaymentPending) return;
    const pollingInterval = setInterval(() => {
      pollForStatus();
    }, 2000);

    return () => {
      clearInterval(pollingInterval);
    };
  }, [isPaymentPending]);

  const openPay = () => {
    const options = {
      atomTokenId,
      merchId,
      custEmail,
      custMobile,
      returnUrl,
    };
    let atom = new window.AtomPaynetz(options, "uat");
  };

  return (
    <div>
      <div>
        <h3>Merchant Shop</h3>
        <p>Transaction Id: {merchTxnId}</p>
        <p>Atom Token Id:{atomTokenId}</p>
        <p>Pay Rs. {amount}</p>
        <div className="btn btn-primary" onClick={openPay}>
          Pay Now
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
