export const TOGGLE_DRAFT_MODAL = "TOGGLE_DRAFT_MODAL";
export const CLOSE_DRAFT_MODAL = "CLOSE_DRAFT_MODAL";

export const TOGGLE_PERMISSION_MODAL = "TOGGLE_PERMISSION_MODAL";
export const CLOSE_PERMISSION_MODAL = "CLOSE_PERMISSION_MODAL";
export const SET_PERMISSION_MODAL_TEXT = "SET_PERMISSION_MODAL_TEXT";
export const defaultPermissionText = "You are not authorized for this action";

export const SET_SELECTED_LEAD_FOR_EDIT = "SET_SELECTED_LEAD_FOR_EDIT";
export const UPDATE_SELECTED_LEAD_FOR_SUBMIT =
  "UPDATE_SELECTED_LEAD_FOR_SUBMIT";
export const SET_SELECTED_EDIT_LEAD_ADDITIONAL_INFORMATION =
  "SET_SELECTED_EDIT_LEAD_ADDITIONAL_INFORMATION";
export const SET_CUSTOMER_DETAILS_FOR_SELECTED_EDIT_LEAD =
  "SET_CUSTOMER_DETAILS_FOR_SELECTED_EDIT_LEAD";
