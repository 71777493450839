import React, { useState } from "react";
import CustomerExpiredTable from "./CustomerExpiredTable";
import Grid from "@mui/material/Grid";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  Col,
  Row,
  Container,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import CustomerActivationsTable from "./CustomerActivationsTable";
import AlreadyExpiry from "./AlredayExpired";
import TodayAboutToExpiry from "./TodayAboutroExpiry";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";

const NewCustomerActivity = (props) => {
  var customerInfo = props.newCustomerData;
  // modal
  const [todayConnection, setTodayConnection] = useState(false);
  const todayConnectionModal = () => setTodayConnection(!todayConnection);
  const [expiredTableRange, setExpiredTableRange] = useState("Expired Today");

  const [customerActivation, setCustomerActivations] = useState(false);
  const activationModal = () => setCustomerActivations(!customerActivation);
  const [activeTable, setActiveTable] = useState("Today");

  // about to expiry
  const [alredyExpired, setalredyExpired] = useState(false);
  const todayalredyExpired = () => setalredyExpired(!alredyExpired);
  const [expiredToday, setExpiredToday] = useState("Today");

  // become expired

  const [becomeExpired, setBecomeExpired] = useState(false);
  const todaybecomeExpired = () => setBecomeExpired(!becomeExpired);
  const [becomeoday, setBecomeToday] = useState("Today");

  const tokenInfo = JSON.parse(localStorage.getItem("token"));
  let ShowTrendIcon = false;
  if (
    (tokenInfo && tokenInfo.user_type === "Admin") ||
    (tokenInfo && tokenInfo.user_type === "Super Admin") ||
    (tokenInfo && tokenInfo.user_type === "Franchise Owner") ||
    (tokenInfo && tokenInfo.user_type === "Branch Owner")
  ) {
    ShowTrendIcon = true;
  }

  return (
    <>
      {" "}
      <Card
        className="custom-card1"
        style={{ borderRadius: "10px", flex: "0 0 100%", height: "100%" }}
      >
        {props.loaderSpinneer ? (
          <Grid container spacing={2} className="loadercenter">
            <Spinner size="lg" className="dashboard_spinner">
              {" "}
            </Spinner>
          </Grid>
        ) : (
          <>
            <Container fluid={true} id="activation_card">
              <Row>
                <Col>
                  <span
                    className="dashboard-font"
                    style={{ position: "relative", left: "-18px" }}
                  >
                    TODAY
                  </span>
                </Col>
                {ShowTrendIcon && (
                  <Col style={{ position: "relative", top: "-11px" }}>
                    <span
                      // className="dashboard-font"
                      style={{ position: "relative", float: "right" }}
                    >
                      <TrendingUpOutlinedIcon
                        className="trendIcon"
                        style={{ cursor: "pointer", position: "sticky" }}
                        onClick={() =>
                          props.setCustomerState(!props.customerState)
                        }
                      />
                    </span>
                  </Col>
                )}
              </Row>
              <Row>
                <Col md="12" lg="12" xl="12" className="box-col-12">
                  <div className="row">
                    <Col
                      sm="3 col-3"
                      xs="3"
                      md="3"
                      lg="3"
                      xl="3"
                      className="col_new"
                    >
                      <div id="act_cols" class="col-md-12">
                        <h6 className="total_test_cust">{"Activations"}</h6>
                        <span
                          className="total_test_cust_1"
                          onClick={() => {
                            setActiveTable("Today");
                            activationModal();
                          }}
                          style={{ color: "#1890ff", cursor: "pointer" }}
                        >
                          {customerInfo && customerInfo.today_activations
                            ? customerInfo && customerInfo.today_activations
                            : "0"}
                        </span>
                      </div>
                    </Col>
                    <Col
                      sm="3 col-3"
                      xs="3"
                      md="3"
                      lg="3"
                      xl="3"
                      className="col_new"
                    >
                      <div id="act_cols" class="col-md-12">
                        <h6
                          className="total_test_cust"
                          style={{ marginLeft: "-1px" }}
                        >
                          {"Expiry - Expired"}
                        </h6>
                        <Row>
                          <Col
                            sm="1 col-1"
                            xs="1"
                            md="1"
                            lg="1"
                            xl="1"
                            className="col_new"
                            style={{ marginLeft: "5px" }}
                          ></Col>
                          <Col
                            sm="4 col-4"
                            xs="4"
                            md="4"
                            lg="4"
                            xl="4"
                            className="col_new"
                            id="becomeExpiry"
                          >
                            <span
                              className="total_test_cust_1"
                              // id="becomeExpiry"
                              onClick={() => {
                                setBecomeToday("Today");
                                todaybecomeExpired();
                              }}
                            >
                              {customerInfo &&
                              customerInfo.today_expiry_of_active_customers
                                ? customerInfo &&
                                  customerInfo.today_expiry_of_active_customers
                                : "0"}
                            </span>
                          </Col>
                          <Col
                            sm="1 col-1"
                            xs="1"
                            md="1"
                            lg="1"
                            xl="1"
                            className="col_new"
                          ></Col>
                          <Col
                            sm="4 col-4"
                            xs="4"
                            md="4"
                            lg="4"
                            xl="4"
                            className="col_new"
                            id="alredyExpiry"
                          >
                            <span
                              className="total_test_cust_1"
                              // id="alredyExpiry"
                              onClick={() => {
                                setExpiredToday("Today");
                                todayalredyExpired();
                                // setExpiredTableRange("Expired Today");
                                // todayConnectionModal();
                              }}
                            >
                              {customerInfo &&
                              customerInfo.today_expiry_of_expired_customers
                                ? customerInfo &&
                                  customerInfo.today_expiry_of_expired_customers
                                : "0"}
                            </span>
                          </Col>
                          <Col
                            sm="1 col-1"
                            xs="1"
                            md="1"
                            lg="1"
                            xl="1"
                            className="col_new"
                          ></Col>
                        </Row>
                      </div>
                    </Col>
                    <Col
                      sm="3 col-3"
                      xs="3"
                      md="3"
                      lg="3"
                      xl="3"
                      className="col_new"
                    >
                      <div id="act_cols" class="col-md-12">
                        <h6 className="total_test_cust">{"Renewals"}</h6>
                        <span className="total_test_cust_1">
                          {customerInfo &&
                          customerInfo.today_renewal &&
                          customerInfo.today_renewal.count
                            ? customerInfo &&
                              customerInfo.today_renewal &&
                              customerInfo.today_renewal.count
                            : "0"}{" "}
                          / ₹{""}
                          {customerInfo &&
                          customerInfo.today_renewal &&
                          customerInfo.today_renewal.amount
                            ? customerInfo &&
                              customerInfo.today_renewal &&
                              customerInfo.today_renewal.amount.toFixed(0)
                            : "0"}
                        </span>
                      </div>
                    </Col>
                    <Col
                      sm="3 col-3"
                      xs="3"
                      md="3"
                      lg="3"
                      xl="3"
                      className="col_new"
                    >
                      <div id="act_cols" class="col-md-12">
                        <h6 className="total_test_cust">{"Payments"}</h6>
                        <span className="total_test_cust_1">
                          <Link
                            to={`${process.env.PUBLIC_URL}/app/billingupdates/billingfields/${process.env.REACT_APP_API_URL_Layout_Name}`}
                          >
                            {customerInfo &&
                              customerInfo?.today_payments &&
                              customerInfo?.today_payments.reduce(
                                (a, v) => (a = a + v.count),
                                0,
                              )}{" "}
                          </Link>{" "}
                          / ₹{""}
                          {customerInfo &&
                            customerInfo?.today_payments &&
                            customerInfo?.today_payments
                              .reduce((a, v) => (a = a + v.amount), 0)
                              .toFixed(0)}
                        </span>
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>
              <span
                className="dashboard-font"
                style={{ position: "relative", left: "-18px" }}
              >
                YESTERDAY
              </span>
              <Row>
                <Col md="12" lg="12" xl="12" className="box-col-12">
                  <div className="row">
                    <Col
                      sm="3 col-3"
                      xs="3"
                      md="3"
                      lg="3"
                      xl="3"
                      className="col_new"
                    >
                      <div id="act_cols" class="col-md-12">
                        <h6 className="total_test_cust">{"Activations"}</h6>
                        <span
                          className="total_test_cust_1"
                          style={{ color: "#1890ff", cursor: "pointer" }}
                          onClick={() => {
                            setActiveTable("Yesterday");
                            activationModal();
                          }}
                        >
                          {customerInfo && customerInfo.yesterday_activations
                            ? customerInfo && customerInfo.yesterday_activations
                            : "0"}
                        </span>
                      </div>
                    </Col>
                    <Col
                      sm="3 col-3"
                      xs="3"
                      md="3"
                      lg="3"
                      xl="3"
                      className="col_new"
                    >
                      <div id="act_cols" class="col-md-12">
                        <h6 className="total_test_cust">{"Expired"}</h6>
                        <span
                          className="total_test_cust_1"
                          style={{ color: "#1890ff", cursor: "pointer" }}
                          onClick={() => {
                            setExpiredTableRange("Expired Yesterday");
                            todayConnectionModal();
                          }}
                        >
                          {customerInfo && customerInfo.yesterday_expiry
                            ? customerInfo && customerInfo.yesterday_expiry
                            : "0"}
                        </span>
                      </div>
                    </Col>
                    <Col
                      sm="3 col-3"
                      xs="3"
                      md="3"
                      lg="3"
                      xl="3"
                      className="col_new"
                    >
                      <div id="act_cols" class="col-md-12">
                        <h6 className="total_test_cust">{"Renewals"}</h6>
                        <span className="total_test_cust_1">
                          {customerInfo &&
                          customerInfo.yesterday_renewal &&
                          customerInfo.yesterday_renewal.yesterday_renwals
                            ? customerInfo &&
                              customerInfo.yesterday_renewal &&
                              customerInfo.yesterday_renewal.yesterday_renwals
                            : "0"}{" "}
                          / ₹{""}
                          {customerInfo &&
                          customerInfo.yesterday_renewal &&
                          customerInfo.yesterday_renewal.amount
                            ? customerInfo &&
                              customerInfo.yesterday_renewal &&
                              customerInfo.yesterday_renewal.amount.toFixed(0)
                            : "0"}
                        </span>
                      </div>
                    </Col>
                    <Col
                      sm="3 col-3"
                      xs="3"
                      md="3"
                      lg="3"
                      xl="3"
                      className="col_new"
                    >
                      <div id="act_cols" class="col-md-12">
                        <h6 className="total_test_cust">{"Payments"}</h6>
                        <span className="total_test_cust_1">
                          <Link
                            to={{
                              pathname: `${process.env.PUBLIC_URL}/app/billingupdates/billingfield/${process.env.REACT_APP_API_URL_Layout_Name}`,
                              state: {
                                billingDateRange: `${moment()
                                  .subtract(1, "d")
                                  .format("YYYY-MM-DD")}&`,
                              },
                            }}
                          >
                            {customerInfo &&
                              customerInfo.yesterday_payments &&
                              customerInfo.yesterday_payments.reduce(
                                (a, v) => (a = a + v.count),
                                0,
                              )}{" "}
                          </Link>
                          / ₹{""}
                          {customerInfo &&
                            customerInfo.yesterday_payments &&
                            customerInfo.yesterday_payments
                              .reduce((a, v) => (a = a + v.amount), 0)
                              .toFixed(0)}
                        </span>
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>
              <span
                className="dashboard-font"
                style={{ position: "relative", left: "-18px" }}
              >
                UPCOMING EXPIRY
              </span>
              <Row>
                <Col md="12" lg="12" xl="12" className="box-col-12">
                  <div className="row">
                    {/* <Col sm="3 col-3" xs="3" md="3" lg="3" xl="3" className="col_new">
                <div id="act_cols" class="col-md-12">
                  <h6 className="total_test_cust">{"Expiry"}</h6>
                  <span
                    className="total_test_cust_1"
                    style={{ color: "#1890ff", cursor: "pointer" }}
                    onClick={() => {
                      setExpiredTableRange("Expiring Next 7 Days");
                      todayConnectionModal();
                    }}

                  >
                    {customerInfo && customerInfo.upcoming_user_expiry_next7days
                      ? customerInfo && customerInfo.upcoming_user_expiry_next7days
                      : "0"}
                  </span>
                </div>
              </Col> */}

                    <Col
                      sm="3 col-3"
                      xs="3"
                      md="3"
                      lg="3"
                      xl="3"
                      className="col_new"
                    >
                      <div id="act_cols" class="col-md-12">
                        <h6 className="total_test_cust">{`${moment()
                          .add(1, "days")
                          .format("MMM Do")}`}</h6>
                        <span
                          className="total_test_cust_1"
                          style={{ color: "#1890ff", cursor: "pointer" }}
                          onClick={() => {
                            setExpiredTableRange(
                              `Expiring - ${moment()
                                .add(1, "days")
                                .format("MMM Do")}`,
                            );
                            todayConnectionModal();
                          }}
                        >
                          {customerInfo &&
                          customerInfo.upcoming_user_expiry_tomorrow
                            ? customerInfo &&
                              customerInfo.upcoming_user_expiry_tomorrow
                            : "0"}
                        </span>
                      </div>
                    </Col>
                    <Col
                      sm="3 col-3"
                      xs="3"
                      md="3"
                      lg="3"
                      xl="3"
                      className="col_new"
                    >
                      <div id="act_cols" class="col-md-12">
                        <h6 className="total_test_cust">{`${moment()
                          .add(2, "days")
                          .format("MMM Do")}`}</h6>
                        <span
                          className="total_test_cust_1"
                          style={{ color: "#1890ff", cursor: "pointer" }}
                          onClick={() => {
                            setExpiredTableRange(
                              `Expiring - ${moment()
                                .add(2, "days")
                                .format("MMM Do")}`,
                            );
                            todayConnectionModal();
                          }}
                        >
                          {customerInfo?.upcoming_user_expiry_day_after_tomorrow
                            ? customerInfo?.upcoming_user_expiry_day_after_tomorrow
                            : "0"}
                        </span>
                      </div>
                    </Col>
                    <Col
                      sm="3 col-3"
                      xs="3"
                      md="3"
                      lg="3"
                      xl="3"
                      className="col_new"
                    >
                      <div id="act_cols" class="col-md-12">
                        <h6 className="total_test_cust">{`${moment()
                          .add(3, "days")
                          .format("MMM Do")}`}</h6>
                        <span
                          className="total_test_cust_1"
                          style={{ color: "#1890ff", cursor: "pointer" }}
                          onClick={() => {
                            setExpiredTableRange(
                              `Expiring - ${moment()
                                .add(3, "days")
                                .format("MMM Do")}`,
                            );
                            todayConnectionModal();
                          }}
                        >
                          {customerInfo?.upcoming_user_expiry_next2days
                            ? customerInfo?.upcoming_user_expiry_next2days
                            : "0"}
                        </span>
                      </div>
                    </Col>
                    <Col
                      sm="3 col-3"
                      xs="3"
                      md="3"
                      lg="3"
                      xl="3"
                      className="col_new"
                    >
                      <div id="act_cols" class="col-md-12">
                        <h6 className="total_test_cust">{`${moment()
                          .add(4, "days")
                          .format("MMM Do")}`}</h6>
                        <span
                          className="total_test_cust_1"
                          style={{ color: "#1890ff", cursor: "pointer" }}
                          onClick={() => {
                            setExpiredTableRange(
                              `Expiring - ${moment()
                                .add(4, "days")
                                .format("MMM Do")}`,
                            );
                            todayConnectionModal();
                          }}
                        >
                          {customerInfo?.upcoming_user_expiry_next3days
                            ? customerInfo?.upcoming_user_expiry_next3days
                            : "0"}
                        </span>
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </Card>
      {/* expiry table for yesterday , next 7 days */}
      <Modal
        isOpen={todayConnection}
        toggle={todayConnectionModal}
        centered
        size="lg"
      >
        <ModalFooter>
          <Button color="secondary" id="resetid" onClick={todayConnectionModal}>
            {"Close"}
          </Button>
        </ModalFooter>
        <ModalBody>
          <h5 style={{ marginTop: "-65px", width: "fit-content" }}>
            Customers {expiredTableRange}
          </h5>
          <CustomerExpiredTable expiredTableRange={expiredTableRange} />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={customerActivation}
        toggle={activationModal}
        centered
        size="lg"
      >
        <ModalFooter>
          <Button color="secondary" id="resetid" onClick={activationModal}>
            {"Close"}
          </Button>
        </ModalFooter>
        <ModalBody>
          <h5 style={{ marginTop: "-65px", width: "70%" }}>
            Customers New Connections {activeTable}
          </h5>
          <CustomerActivationsTable activeTable={activeTable} />
        </ModalBody>
      </Modal>
      {/* already expired */}
      <Modal
        isOpen={alredyExpired}
        toggle={todayalredyExpired}
        centered
        size="lg"
      >
        <ModalFooter>
          <Button color="secondary" id="resetid" onClick={todayalredyExpired}>
            {"Close"}
          </Button>
        </ModalFooter>
        <ModalBody>
          <h5 style={{ marginTop: "-65px", width: "fit-content" }}>
            Customers Expired {expiredToday}
          </h5>
          <AlreadyExpiry expiredToday={expiredToday} />
        </ModalBody>
      </Modal>
      {/* become expiry */}
      <Modal
        isOpen={becomeExpired}
        toggle={todaybecomeExpired}
        centered
        size="lg"
      >
        <ModalFooter>
          <Button color="secondary" id="resetid" onClick={todaybecomeExpired}>
            {"Close"}
          </Button>
        </ModalFooter>
        <ModalBody>
          <h5 style={{ marginTop: "-65px", width: "fit-content" }}>
            Customers Expiring {becomeoday}
          </h5>
          <TodayAboutToExpiry becomeoday={becomeoday} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default NewCustomerActivity;
