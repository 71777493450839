import React, { useEffect, useState } from "react";
import {
  billingCycle,
  billingType,
  expiryDate,
  fupCalculation,
  packageDatatype,
  packageType,
  statusType,
} from "./data";
import Collapse from "../../../assets/images/up-arrow.svg";
import Expand from "../../../assets/images/down-arrow.svg";

/* eslint-disable react/prop-types */
export const getPackageType = (row) => {
  let statusObj = packageType.find((s) => s.id == row.package_type);
  return statusObj ? statusObj.name : row.package_type;
};

export const getPackageDataType = (row) => {
  let statusObj = packageDatatype.find((s) => s.id == row.package_data_type);
  return statusObj ? statusObj.name : "-";
};

export const getFUPCalculation = (row) => {
  let statusObj = fupCalculation.find((s) => s.id == row.fup_calculation_type);
  return statusObj ? statusObj.name : "-";
};

export const getFallbackPlan = (row) => {
  if (typeof row.fall_back_plan === "object" && row.fall_back_plan !== null) {
    // If it's an object, return the package_name
    return row.fall_back_plan.package_name;
  } else if (typeof row.fall_back_plan === "string") {
    // If it's a string, return the string itself
    return row.fall_back_plan;
  } else {
    // If it's null or any other type, return 'N/A'
    return "---";
  }
};

export const getBillingType = (row) => {
  let statusObj = billingType.find((s) => s.id == row.billing_type);
  return statusObj ? statusObj.name : "-";
};

export const getBillingCycle = (row) => {
  let statusObj = billingCycle.find((s) => s.id == row.billing_cycle);
  return statusObj ? statusObj.name : "-";
};

export const getStatus = (row) => {
  let statusObj = statusType.find((s) => s.id == row.status);
  return statusObj ? statusObj.name : "-";
};

export const getRenewalExpiryDay = (row) => {
  let statusObj = expiryDate.find((s) => s.id == row.renewal_expiry_day);
  return statusObj ? statusObj.name : "-";
};

const Row = ({
  row,
  idx,
  onSelectRow,
  serviceIdClickHandler,
  serviceSelectedCheckboxObjects,
  subCheckboxHandler,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const renderIcon = () => {
    if (row.sub_plans?.length) {
      if (isExpanded)
        return (
          <img
            src={Collapse}
            style={{ marginLeft: "5px", marginRight: "10px" }}
            onClick={() => setIsExpanded(false)}
          />
        );
      return (
        <img
          src={Expand}
          style={{ marginLeft: "5px", marginRight: "10px" }}
          onClick={() => setIsExpanded(true)}
        />
      );
    }
    return <span style={{ height: "5px", width: "10px", padding: "15px" }} />;
  };

  return (
    <>
      <tr key={row.id}>
        <td style={{ textTransform: "uppercase" }}>
          <input
            type="checkbox"
            checked={!!row.isSelected}
            onChange={() => onSelectRow(row, !row.isSelected)}
          />
          <span className="divider" />
          {renderIcon()}
          <span onClick={() => serviceIdClickHandler(row)}>
            {row.package_name}
          </span>
        </td>
        <td>{row.id}</td>
        <td>{row.sub_plans?.length ? "Yes" : "No"}</td>
        <td> ₹ {parseFloat(row.total_plan_cost).toFixed(2)}</td>
        <td>
          {row.time_unit +
            " " +
            (row.unit_type === "mon" ? "Month" : row.unit_type) +
            (row.time_unit > 1 ? "s" : "")}
        </td>
        {/* <td>{"---"}</td> */}
        <td>{row.download_speed}</td>
        {/* <td>{row.user_count}</td> */}
        <td>{(row.is_static_ip || "false").toString()}</td>
        {/* <td>{row.upload_speed}</td> */}
        <td>{getPackageType(row)}</td>
        {/* <td>{getPackageDataType(row)}</td> */}
        {/* <td>{getFUPCalculation(row)}</td> */}
        {/* <td>{getFallbackPlan(row)}</td> */}
        <td>{getBillingType(row)}</td>
        {/* <td>{getBillingCycle(row)}</td>
        <td>{getStatus(row)}</td>
        <td>{row ? row.plan_cost : "-"}</td>
        <td>{row ? row.plan_cgst : "-"}</td>
        <td>{row ? row.plan_sgst : "-"}</td>
        <td>{parseFloat(row.total_plan_cost).toFixed(2)}</td>
        <td>{getRenewalExpiryDay(row)}</td>
        <td>{row ? row.time_unit + " " + row.unit_type + "(s)" : "-"}</td> */}
      </tr>
      {isExpanded
        ? row.sub_plans?.map((plan, idx) => (
            <tr key={plan.id}>
              <td
                style={{
                  paddingLeft: "65px",
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <input
                  type="checkbox"
                  onChange={(e) => subCheckboxHandler(e, row.id, plan.id)}
                  checked={
                    !!serviceSelectedCheckboxObjects?.[row.id]?.includes?.(
                      plan.id,
                    )
                  }
                  style={{ marginRight: "15px" }}
                />
                {plan.package_name}
              </td>
              <td>{plan.id}</td>
              <td>{idx + 1}</td>
              <td> ₹ {parseFloat(plan.total_plan_cost).toFixed(2)}</td>
              <td>
                {plan.time_unit +
                  " " +
                  (plan.unit_type === "mon" ? "Month" : plan.unit_type) +
                  (plan.time_unit > 1 ? "s" : "")}
              </td>
              {/* <td> {plan.offer?.name ? plan.offer.name : "---"}</td> */}
              <td>{row.download_speed}</td>
              {/* <td>{plan.user_count ? plan.user_count : "0"}</td> */}
              <td>{(row.is_static_ip || "false").toString()}</td>

              {/* <td>{row.upload_speed}</td> */}
              <td>{getPackageType(row)}</td>
              <td>{getBillingType(row)}</td>
              {/* <td>{getPackageDataType(row)}</td>
              <td>{getFUPCalculation(row)}</td>
              <td>{getFallbackPlan(row)}</td>
              <td>{getBillingCycle(row)}</td>
              <td>{getStatus(row)}</td>
              <td>{row ? row.plan_cost : "-"}</td>
              <td>{row ? row.plan_cgst : "-"}</td>
              <td>{row ? row.plan_sgst : "-"}</td>
              <td>{parseFloat(row.total_plan_cost).toFixed(2)}</td>
              <td>{getRenewalExpiryDay(row)}</td>
              <td>{row ? row.time_unit + " " + row.unit_type + "(s)" : "-"}</td> */}
            </tr>
          ))
        : null}
    </>
  );
};

export default Row;
